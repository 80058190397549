import 'slick-carousel'

jQuery( document ).ready(function($) {

  $('.menu-trigger').click(function(){
    $(this).toggleClass('active')
    $('header .menu').toggleClass('active')
  })

  $('.slider-writters').slick({
    slidesToShow: 1,
    rows: 2,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          rows: 1,
        }
      }
    ]
  });

  $('.slider-videos').slick({
    slidesToShow: 1,
    rows: 3,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          rows: 1,
        }
      }
    ]
  });
  
  $('.popup .close').click(function(){
    $(this).parent().parent().parent().removeClass('active')
    $('.popup.videos .featured-video').attr('src', ``)
  })

  $('.video-trigger').click(function(e){
    e.preventDefault()
    const youtubeId = $(this).attr('data-video-id')
    $('.popup.videos').addClass('active')
    $('.popup.videos .featured-video').attr('src', `https://www.youtube.com/embed/${youtubeId}`)
  })

});